import React from "react"
import styled from "styled-components"

// Utils
import { sanitizeTitle } from "../../utils/sanitize"

export default ({ content, format }) => {
  return (
    <Title titleFormat={format}>{content ? sanitizeTitle(content) : ""}</Title>
  )
}

// Styled Components
const Title = styled.h2`
  article:hover & {
    color: ${props => props.theme.color.blue["400"]};
  }
  ${props =>
    props.titleFormat === `list` || props.titleFormat === `search`
      ? `
        font-size:1rem;
        `
      : `
        font-size:1.5rem;
    `}
  @media (min-width:992px) {
    ${props =>
      props.titleFormat === `list`
        ? `
            font-size:1.3125rem;
        `
        : `
            font-size:1.75rem;
        `}
  }
`
