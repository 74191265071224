import he from "he"

export const sanitizeTitle = content => {
  return he.decode(content)
}

export const sanitizeExceprt = (content, charLimit) => {
  const cleanContent = he.decode(content.replace(/<[^>]*>?/gm, ""))

  let trimContent = cleanContent.substring(0, charLimit || 170)

  trimContent = trimContent.substr(
    0,
    Math.min(trimContent.length, trimContent.lastIndexOf(" "))
  )

  return trimContent
}
