import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import classNames from "classnames"

export default ({ avatar, hideAvatar, name, slug }) => {

  const className = classNames({
    "avatar--hidden": hideAvatar
  })
  
  return (
    <Author
      as={Link}
      className={className}
      aria-label={`Discover more from ${name}`}
      to={`/author/${slug}`}
    >
      {avatar ? <Avatar alt={name} fixed={avatar} /> : null}
      {name}
    </Author>
  )
}

// Styled Components
const Author = styled.a`
  line-height: 1;
  font-weight: bold;
  letter-spacing: 0.08em;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.75rem;
  z-index: 2;
  display:flex;
  margin-top: auto;
  color:${props => props.theme.color.neutral["600"]};
  &.avatar--hidden {
    display:none;
    @media (min-width:992px) {
        color:${props => props.theme.color.neutral["400"]};
        margin-left: 1rem;
        display:flex;
        margin-top:0;
    }
  }
  &:hover {
    color: ${props => props.theme.color.blue["400"]};
  }
`
const Avatar = styled(Img)`
  border-radius: 100%;
  margin-right: 1rem;
`
