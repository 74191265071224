import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

export default ({ name, slug, plalette }) => {
  return (
    <Category plalette={plalette} to={`/${slug}`}>
      {name}
    </Category>
  )
}

// Styled Components
const Category = styled(Link)`
  line-height: 1;
  padding: 0.5rem 1rem;
  width: fit-content;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 500;
  z-index: 2;
  ${props =>
    props.plalette
      ? `
        ${
          props.plalette === `neutral`
            ? `
            color:${props.theme.color.neutral["600"]};
            background-color:${props.theme.color.neutral["15"]};
        `
            : ``
        }
        ${
          props.plalette === `blue`
            ? `
            color:${props.theme.color.blue["400"]};
            background-color:${props.theme.color.blue["50"]};
        `
            : ``
        }
    `
      : `
        color:${props.theme.color.neutral["600"]};
        background-color:${props.theme.color.blue["50"]};
    `}
  &:hover {
    color: ${props => props.theme.color.blue["400"]};
  }
`
