import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

// Hooks
import { useBgPlaceholder } from "../../hooks/placeholder/useBgPlaceholder"

export default ({ image }) => {
  return (
    <Wrapper>
      <Background
        fluid={image ? image : useBgPlaceholder()}
        style={{
          position: "absolute",
        }}
      />
    </Wrapper>
  )
}

// Styled Components
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
`
const Background = styled(Img)`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`
