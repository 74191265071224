import React from "react"
import styled from "styled-components"

// utils
import { sanitizeExceprt } from "../../utils/sanitize"

export default ({ content, format }) => {
  return (
    <Description format={format}>
      {content ? `${sanitizeExceprt(content, 178)}...` : ""}
    </Description>
  )
}

// Styled Components
const Description = styled.p`
  ${props =>
    props.format === `search`
      ? `
        @media (max-width:991px) {
            display: none;
        }
    `
      : ``}
`
