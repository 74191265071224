import { useStaticQuery, graphql } from "gatsby"

export const useBgPlaceholder = () => {
  const { file } = useStaticQuery(graphql`
    query useBgPlaceholderQuery {
      file(sourceInstanceName: { eq: "placeholder" }, name: { eq: "bg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return file.childImageSharp.fluid
}
